.cart-button {
  transform: scale(0);
  transition: transform ease-in-out 0.1s;
  position: absolute;
  bottom: calc(-1 * var(--gap-6xl));
  right: var(--gap-xl);
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 60px;
  aspect-ratio: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-button_visible {
  transform: scale(1);
}

.cart-button::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--color-light-bg-secondary-inverted);
  transition: width ease-in-out 0.15s;
}

.cart-button:hover::before {
  width: 110%;
}

.cart-button__count {
  position: absolute;
  bottom: 0;
  right: 0;
}
