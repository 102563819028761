@import url(./vendor/fonts/styrene/index.css);
@import '@alfalab/core-components/vars/gaps.css';
@import '@alfalab/core-components/vars/colors-indigo.css';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-family: 'Styrene UI', sans-serif;
  box-sizing: border-box;
  margin: 0;
  --page-padding: var(--gap-2xs);
}

@media (min-width: 768px) {
  body {
    --page-padding: var(--gap-s);
  }
}

@media (min-width: 1280px) {
  body {
    --page-padding: var(--gap-xl);
  }
}

a,
a:hover,
a:visited {
  text-decoration: none;
  color: inherit;
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-section {
  padding-inline: var(--page-padding);
}
