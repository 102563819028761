.main {
  display: grid;
  grid-template-columns: 1fr;
  margin-inline: calc(-1 * var(--page-padding));
}

@media (min-width: 768px) {
  .main {
    grid-template-columns: 1fr 1fr;
  }
}

.main__link {
  position: relative;
  display: block;
  aspect-ratio: 1;
  padding: var(--gap-s);
  background-position: center;
  background-size: 100%;
  transition: background-size ease-out 0.2s;
}

.main__link_type_create {
  background-image: url(./create.jpeg);
}

.main__link_type_store {
  background-image: url(./store.jpeg);
}

.main__link:hover {
  background-size: 110%;
}
